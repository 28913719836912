import React from 'react'
import Header from './header/header'
import About from './about/about'
import Offres from './offres/offres'
import Contact from './contact/contact'

const Home = () => {
  return (
    <div className=''>
        <Header />
        <About />
        <Offres />
        <Contact />
    </div>
  )
}

export default Home