import React from 'react'

const Desktop = ( {links, logo} ) => {
  return (
    <div className='flex flex-row justify-between'>
        <section className='text-2xl font-bold'>
            <img src={logo} alt="PEB logo" />
        </section>
        <section className='hidden lg:flex'>
            <ul className='flex flex-row gap-4 self-center'>
                {links.map(([link, id], index) => {
                    return(
                        <li key={index} className='text-theme-1 hover:text-theme-2'>
                            <a href={`./#${id}`}>{link}</a>
                        </li>
                    )
                })}
            </ul>
        </section>
    </div>
  )
}

export default Desktop