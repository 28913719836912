import React, { useState, useEffect } from "react";
import Footer from "./layout/footer/footer";
import Home from "./layout/home/home";
import Nav from "./layout/nav/nav";

function App() {
  const [isLoading, setLoading] = useState(true);

  function fakeRequest() {
    return new Promise(resolve => setTimeout(() => resolve(), 2000));
  }

  useEffect(() => {
    fakeRequest().then(() => {
      const el = document.querySelector(".loader-container");
      if (el) {
        el.remove();
        setLoading(!isLoading);
      }
    });
  }, );

  if (isLoading) {
    return null;
  }

  return (
    <div className="App">
      <Nav />
      <Home />
      <Footer />
    </div>
  );
}

export default App;
