import React from 'react'

const Button = ( {text, id, callback} ) => {
  return (
    <button className='py-2 bg-theme-4 rounded text-theme-5 font-bold' onClick={callback}>
        {
            id ? <a className='px-4 py-2' href={`./#${id}`}>{text}</a> : {text}
        }
    </button>
  )
}

export default Button