import React from 'react'
import Logo from '../../images/logo.png'

const Footer = () => {
    const links = [["A propos", "Apropos"], ["Services","Services"], ["contacter", "contacter"]]
  return (
    <div className=''>
        <div className='w-full md:w-4/5 mx-auto px-4 md:py-8'>
            <div className='flex flex-col md:flex-row md:gap-0 items-center lg:items-end md:justify-between py-8'>
                <section className='justify-self-start font-bold'>
                    <img src={Logo} alt="PEB logo" />
                </section>
                <section className='hidden md:block justify-self-end'>
                    <ul className='flex flex-col md:flex-row gap-6'>
                        {links.map(([link, id], index) => {
                            return(
                                <li key={index} className='w-fit mx-auto text-theme-1 hover:text-theme-2'>
                                    <a href={`./#${id}`}>{link}</a>
                                </li>
                            )
                        })}
                    </ul>
                </section>
            </div>
            <div>
                <p className='font-light text-center text-theme-2'>All rights reserved SARL PEB, 2022</p>
                <p className='font-light text-center text-theme-2'>Developped by <a className='text-theme-1' href='https://www.facila.dev/' target="_blank" rel="noreferrer" >Facila.dev</a></p>
            </div>
        </div>
    </div>
  )
}

export default Footer