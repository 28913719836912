import React from 'react'

import AboutIMG from '../../../images/about.jpg'
// import AboutIMG2 from '../../../images/about2.jpeg'
// import AboutIMG3 from '../../../images/about3.jpeg'
// import AboutIMG4 from '../../../images/about.jpeg'

const About = () => {
  return (
    <div id='Apropos' className='w-full md:w-4/5 mx-auto py-12'>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
            <div className='flex flex-col gap-4 w-2/3 mx-auto my-auto text-theme-3'>
                <p className='font-light'>Depuis 2016</p>
                <h1 className='text-xl font-bold'>A porpos de PEB</h1>
                <p className='text-justify'>
                    SARL PEB est une société à responsabilité limitée, spécialisé dans la production des poteaux en béton précontraint. Elle est située à Ain temouchent, précisément au cœur de la zone industriel.
                </p>
                <p className='text-justify'>
                    Depuis sa création, SARL PEB veille sur la qualité, la disponibilité et la rapidité de livraison .Doté des équipements professionnels et performant ainsi qu’une équipe qualifiée et dynamique à fin de satisfaire ses clients et fabriquer des produits conformes aux exigences de marché Algérienne.
                </p>
            </div>
            {/* <div className='grid grid-cols-2 gap-4 px-2 place-items-stretch '>
                <img  src={AboutIMG} alt="about peb" />
                <img  src={AboutIMG2} alt="about peb" />
                <img  src={AboutIMG3} alt="about peb" />
                <img  src={AboutIMG} alt="about peb" />
            </div> */}

            <div className='w-full h-full'>
                <img className='h-full mx-auto' src={AboutIMG} alt="about peb" />
            </div>
        </div>
    </div>
  )
}

export default About