import React from 'react'
import Desktop from './desktop'
import Mobile from './mobile'

import Logo from '../../images/logo.png'

const Nav = () => {
  const navLinks = [["A propos", "Apropos"], ["Services","Services"], ["contacter", "contacter"]]
  return (
    <>
      <div className='hidden lg:block w-full md:w-4/5 mx-auto px-4'>
          {/* Desktop Nav  */}
          <Desktop links={navLinks} logo={Logo} />
      </div>
      <div className='lg:hidden w-full md:w-4/5 mx-auto px-4'>
          {/* mobile Nav  */}
          <Mobile links={navLinks} logo={Logo} />
      </div>
    </>
  )
}

export default Nav