import React from 'react'
import Button from '../../../components/button'

// import AboutIMG from '../../../images/about.png'

const services = [
  "SUPPORT BETON PRECONTRAINT 9*250",
  "SUPPORT BETON PRECONTRAINT 9*400",
  "SUPPORT BETON PRECONTRAINT 9*630",
  "SUPPORT BETON PRECONTRAINT 11*250",
  "SUPPORT BETON PRECONTRAINT 11*400",
  "SUPPORT BETON PRECONTRAINT 11*630",
  "SUPPORT BETON PRECONTRAINT 11*1000",
  "SUPPORT BETON PRECONTRAINT 12*250",
  "SUPPORT BETON PRECONTRAINT 12*400",
  "SUPPORT BETON PRECONTRAINT 12*630",
  "SUPPORT BETON PRECONTRAINT 12*1000",
]

const Offres = () => {
  return (
    <div className='bg-theme-3 text-white'>
      <div id='Services' className='w-full md:w-4/5 mx-auto py-12'>
          <div className='flex flex-col gap-6 items-center'>
              <h1 className='text-5xl py-4 font-bold text-theme-5'>Services</h1>
              <p className='font-light w-2/3 md:w-1/2 text-center'>
                L’unité de production PEB offre à sa clientèle une gamme de produit très diversifie qui répond au marché national
              </p>
              {/* <div className='grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4 my-8 px-2'>
              <img src={AboutIMG} alt="about peb" />
              <img src={AboutIMG} alt="about peb" />
              <img src={AboutIMG} alt="about peb" />
              <img src={AboutIMG} alt="about peb" />
              </div> */}

              {/* in case more pics were provided  */}

              <div className='py-4'>
                <ul className='flex flex-col gap-2'>
                  {
                    services.map((service, index) => {
                      return(
                        <li key={index}>{service}</li>
                      )
                    })
                  }
                </ul>
              </div>

              <p className='font-light w-2/3 md:w-1/2 text-center'>
                Nous sommes homologué par SONELGAZ et certifié par ISO 9001.
              </p>

              <Button text="Demander un devis" id="contacter" />
          </div>
      </div>
    </div>
  )
}

export default Offres