import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion';
import { MenuButton } from './menuButton'

const wrapperVariants = {
  initial: {
    clipPath: 'polygon(0 0, 0 0, 0 100%, 0% 100%)', 
    transition: { duration: .4 }
  },
  animate: {
    clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
    transition: { duration: .4, staggerChildren: .1 }
  },
  exit: {
    clipPath: 'polygon(100% 0, 100% 0, 100% 100%, 100% 100%)',
    transition: { duration: .4 }
  }
}

const Mobile = ( {links, logo} ) => {
  const [isOpen, setOpen] = useState(false);

  const handleNav = () => {
    document.body.style.overflow = isOpen ? 'unset' : 'hidden'
    setOpen(!isOpen)
  }
  return (
    <div className={`flex flex-row justify-between items-center ${isOpen ? "relative top-0 left-0 right-0 z-10" : ""}`}>
      <section className='text-2xl font-bold z-50'>
          <img src={logo} alt="PEB logo" />
      </section>
      <section className='z-50' >
        <MenuButton
          isOpen={isOpen}
          onClick={() => handleNav()}
          strokeWidth="2"
        />
      </section>

      <AnimatePresence exitBeforeEnter initial={false}>
        {
          isOpen && 
          (
            <motion.div 
                className='fixed top-0 left-0 h-screen w-screen z-20 bg-theme-5'
                key="card"
                variants={wrapperVariants}
                initial="initial"
                animate="animate"
                exit="exit"
              >
              <ul className='absolute top-0 left-0 bottom-0 right-0 flex flex-col gap-6 justify-center items-center bg-theme-5
                       text-xl'>
                {links.map(([link, id], index) => {
                  return(
                    <li key={index} className='text-xl font-bold text-theme-1'>
                      <a href={`./#${id}`} onClick={() => handleNav()}>{link}</a>
                    </li>
                  )
                })}
              </ul>
            </motion.div>
          )
        }
      </AnimatePresence>

    </div>
  )
}

export default Mobile