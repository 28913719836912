import React from 'react'
import Button from '../../../components/button'
import HeaderBG from '../../../images/header.png'


const Header = () => {
  return (
    <div className='relative h-[90vh]'>
        <div className='h-full w-screen overflow-hidden'>
            <img className='w-full h-full' src={HeaderBG} alt="background" />
        </div>
        <div className='absolute top-0 w-full h-full flex justify-center items-center'>
            <div className='flex flex-col gap-4 w-fit items-center'>
                <p className='text-theme-5 font-normal text-center'>La qualité est notre spécialité</p>
                <h1 className='text-2xl text-center md:text-4xl font-bold text-theme-5'>
                    PRODUCTION POTEAUX <br />
                    EN BETON PRECONTRAINT
                </h1>
                <p className='text-theme-5 font-light text-center w-2/3 md:w-full'>
                    SARL PEB pour toute vous constructions bétonieres 
                </p>
                <Button text="Nous contacter" id="contacter" />
            </div>
        </div>
    </div>
  )
}

export default Header