import React from 'react'

const Contact = () => {
  return (
    <div id='contacter' className='w-full md:w-4/5 mx-auto py-12'>
        <div className='flex flex-col gap-6 items-center'>
            <h1 className='text-4xl md:text-5xl font-bold text-center text-theme-1'>
                Voulez-vous commencer <br />
                un Projet avec PEB ?
            </h1>

            <p className='self-center text-center w-2/3 md:w-fit'>
                Contactez-nous, ou prenez rendez-vous à notre bureau.
            </p>

            <div className='grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-0 w-full px-2'>
                <iframe className='w-full' title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3778.3052771059324!2d-1.1263069896529874!3d35.30623652151488!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd7ed11a978a6cc7%3A0x1a2d6794833328f5!2sSARL%20PEB!5e0!3m2!1sen!2sdz!4v1670756960838!5m2!1sen!2sdz" width="600" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

                <div className='flex flex-col gap-4 px-4 md:px-12 justify-center'>
                    <div className='grid grid-cols-12 items-center'>
                        <svg className='col-span-2' xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"><path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                        <p className='col-span-10'>
                            N° 07, Zone des activités industrielles
                            Ain temouchent
                        </p>
                    </div>
                    <div className='grid grid-cols-12 items-center'>
                        <svg className='col-span-2' xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                        <p className='col-span-10'>
                            <a className='hover:text-theme-2' href="tel:06 60 13 25 74" >06 60 13 25 74 </a> 
                            / 
                            <a className='hover:text-theme-2' href="tel:05 54 03 39 44" > 05 54 03 39 44 </a>
                            / 
                            <a className='hover:text-theme-2' href="tel:06 59 67 98 72" > 06 59 67 98 72</a>
                        </p>
                    </div>
                    <div className='grid grid-cols-12 items-center'>
                        <svg className='col-span-2' xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"><rect x="2" y="4" width="20" height="16" rx="2"></rect><path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"></path></svg>
                        <p className='col-span-10'>
                            <a className='hover:text-theme-2' href="mailto:sarlpebcommercial@gmail.com" >sarlpebcommercial@gmail.com</a>
                            
                        </p>
                    </div>
                    <div className='grid grid-cols-12 items-center'>
                        <svg className='col-span-2' xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg>
                        <p className='col-span-10'>
                            <a className='hover:text-theme-2' href="https://www.facebook.com/profile.php?id=61557370769572&mibextid=ZbWKwL" target="_blank" rel="noreferrer">SARL PEB</a>
                        </p>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
  )
}

export default Contact